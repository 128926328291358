let global_tab_number      = 0;
let alreadySend	= false;

const clearPrefix = /^live_LeaAbfrage-\d+-/

let uid = 0
let prefixId = ''

const form = document.querySelector('form[id^="live_LeaAbfrage"]');

let submitButton = undefined

if(form) {
	///////////////////////////////////////////////
	/////////////////// Init /////////////////////
	/////////////////////////////////////////////

	$('.content-margin').css('display', 'none');
	$('.content-main').removeClass('col-md-8').addClass('col-md-12');

	$('#lea-filelist-box').css('display','block');
	$('#uploader_LeaUpload1234').css('display','block');

	//
	$('.lea_versendet').parent().parent().parent().css('display', 'none');
	//

	///////////////////////////////////////////////
	///////////// Reiter/Tab oben ////////////////
	/////////////////////////////////////////////

	const reiter =
		'<div class="tab_leiste" style="position:relative; display:-webkit-flex; display:flex; margin:35px 0; word-wrap:break-word; ' +
		' -moz-hyphens: auto; -o-hyphens: auto; -webkit-hyphens: auto; -ms-hyphens: auto; hyphens: auto; cursor:pointer; ' +
		'">' +

		'<div class="lea_tab tab_0 leabtn" value="0" ' +
		'style="display:inline; width:20%; height: unset; text-align:center; padding: 5px; color: black; ' +
		'vertical-align:middle; font-size: 100%; border: none; margin-right:5px; background-color:#E3E3E3;' +
		'">Grund&shy;daten</div>' +

		'<div class="lea_tab tab_1 leabtn" value="1" ' +
		'style="display:inline; width:20%; height: unset; text-align:center; padding: 5px; color: black; ' +
		'vertical-align:middle; font-size: 100%; border: none; margin-right:5px; background-color:#E3E3E3;' +
		'">Projekt</div>' +

		'<div class="lea_tab tab_2 leabtn" value="2" ' +
		'style="display:inline; width:20%; height: unset; text-align:center; padding: 5px; color: black; ' +
		'vertical-align:middle; font-size: 100%; border: none; margin-right:5px; background-color:#E3E3E3;' +
		'">Partner</div>' +

		'<div class="lea_tab tab_3 leabtn" value="3" ' +
		'style="display:inline; width:20%; height: unset; text-align:center; padding: 5px; color: black; ' +
		'vertical-align:middle; font-size: 100%; border: none; margin-right:5px; background-color:#E3E3E3;' +
		'">Statistik</div>' +

		'<div class="lea_tab tab_4 leabtn" value="4" ' +
		'style="display:inline; width:20%; height: unset; text-align:center; padding: 5px; color: black; ' +
		'vertical-align:middle; font-size: 100%; border: none; background-color:#E3E3E3;' +
		'">Zusammen&shy;fassung</div>' +
		'</div>';

	const content =
		'<div id="tab_cc" class="clearfix">' +
		'<div id="tab_dummy_0" style="display:none"></div>' +
		'<div id="tab_dummy_1" style="display:none"></div>' +
		'<div id="tab_dummy_2" style="display:none"></div>' +
		'<div id="tab_dummy_3" style="display:none"></div>' +
		'<div id="tab_container_4" style="display:none">' +
		'<div id="zusammenfassung-static" class="static-text clearfix" style="padding: 15px 0px 0px 15px;">' +
		'<p>Nach dem Drücken des Buttons &bdquo;Bewerbung &uuml;berpr&uuml;fen&rdquo; werden hier Hinweise gegeben, was in Ihrer Bewerbung noch fehlt.</p>' +
		'<p>Der &bdquo;Absenden&rdquo;-Button erscheint erst dann, wenn Sie alle Pflichtfelder richtig ausgefüllt haben.</p> <br>' +
		'</div>' +
		'<div id="zusammenfassung-container" class="static-text clearfix" style="padding: 15px 0px 0px 15px;"> </div>' +
		'</div>' +
		'</div>' ;

	function create_tab( tab_nr, vis )
	{
		var tmp = $(
			'<div id="tab_container_' + tab_nr + '" style="display:' + vis + '">' +
			'<div id="tab_static_' + tab_nr + '"></div>' +
			'<div id="tab_grid_' + tab_nr + '"></div>' +
			'</div>' );

		$('#tab_dummy_' + tab_nr).replaceWith(tmp);

		var stat = $('.lea_static_tab_' + tab_nr).parent().css('padding','15px 0 0 15px');    //.css('padding','0 15px 15px 0');
		var grid = $('.lea_grid_tab_' + tab_nr);

		grid.addClass('row')

		$('#tab_static_' + tab_nr).replaceWith(stat);
		$('#tab_grid_' + tab_nr).replaceWith(grid);
	}


	function goto_tab(old_tab, new_tab)
	{
		$('.tab_' + old_tab).css({'background-color':'rgba(20,20,20,0.5)', 'color':'white'});
		$('#tab_container_' + old_tab).css('display', 'none');
		$(submitButton).css('display','none');

		global_tab_number = new_tab;

		$('.tab_'+global_tab_number).css({'background-color':'#ffd720', 'color':'#444'});
		$('#tab_container_' + global_tab_number).css('display', 'block');

		if(global_tab_number >= 3) $(submitButton).css('display','block');

		if(global_tab_number == 0) $('#button_zurueck').css('display','none');
		else					   $('#button_zurueck').css('display','inline');

		if(global_tab_number == 4) $('#button_vor').css('display','none');
		else					   $('#button_vor').css('display','inline');

		$('html').animate({scrollTop: '80px'}, 650);
		$('body').animate({scrollTop: '80px'}, 650);
	}


	$('body').on('click', '.lea_tab', function (event) {
		goto_tab( global_tab_number, parseInt( $(event.target).attr('value') ) );
	});

	function check_bewerbung()
	{
		function check_text(fld, err_txt)
		{
			if( $(fld).val().length < 1 ) return '<p>' + err_txt + '</p>';
			else return '';
		}
		function check_drpd(fld, err_txt)
		{
			if( ($(fld).val() === '0') || ($(fld).val() === '') )
				return '<p>' + err_txt + '</p>';
			else return '';
		}
		function check_chkb(flds, err_txt)
		{
			for (let i = 0; i < flds.length; i++)
				if( $(flds[i]).prop('checked') ) return '';
			return '<p>' + err_txt + '</p>';
		}
		function check_firmengroesse()
		{
			var text_ueberpr = "";
			var bereich	= $('#live_LeaAbfrage-'+`${uid}`+'-groesse').val();
			var groesse	= $('#live_LeaAbfrage-'+`${uid}`+'-groesse_genau').val();

			if( (bereich === '0') || (bereich === '') ) 				text_ueberpr += 'Bei &bdquo;Unternehmensgröße&rdquo; muss die passende Kategorie muss gewählt werden.';
			else if( (bereich == '1' && (groesse < 1 || groesse > 19))
				|| (bereich == '2' && (groesse < 20 || groesse > 149))
				|| (bereich == '3' && (groesse < 150 || groesse > 500)) )	text_ueberpr += 'Bei &bdquo;genaue Zahl&rdquo; muss die für die Kategorie passende genaue Anzahl angegeben werden.';

			if(text_ueberpr.length > 0)
				return '<p>Grunddaten, 1. Angaben zum Unternehmen, Unternehmensgröße: ' + text_ueberpr + '</p>';
			else
				return '';
		}
		function check_zeitraum()
		{
			var text_ueberpr = "";
			var von  = $('#live_LeaAbfrage-'+`${uid}`+'-proj_von').val();
			var bis  = $('#live_LeaAbfrage-'+`${uid}`+'-proj_bis').val();

			if( (von.length != 10) || ((bis.length != 10) && (bis.length != 0)) ) text_ueberpr = 'bitte jeweils ein gültiges Datum eingeben. Das Enddatum darf auch leer sein.';
			else{
				var svon = (new Date(von)).valueOf();
				var sbis = (new Date(bis)).valueOf();
				if(sbis-svon < 0) text_ueberpr = 'das erste Datum muss vor dem zweiten Datum liegen.';
			}

			if(text_ueberpr.length > 0)
				return '<p>Projekt, 3. Zeitraum: ' + text_ueberpr + '</p>';
			else
				return '';
		}
		function check_kooperationspartner()
		{
			var text_ueberpr = "";

			text_ueberpr += check_text ('#live_LeaAbfrage-'+`${uid}`+'-koop1_name',		'Kooperationspartner 1, Name');
			text_ueberpr += check_text ('#live_LeaAbfrage-'+`${uid}`+'-koop1_strasse',	'Kooperationspartner 1, Strasse');
			text_ueberpr += check_text ('#live_LeaAbfrage-'+`${uid}`+'-koop1_p_l_z',		'Kooperationspartner 1, Postleitzahl');
			text_ueberpr += check_text ('#live_LeaAbfrage-'+`${uid}`+'-koop1_ort',		'Kooperationspartner 1, Ort');
			text_ueberpr += check_drpd ('#live_LeaAbfrage-'+`${uid}`+'-koop1ap_anrede',	'Kooperationspartner 1, Ansprechpartner Anrede');
			text_ueberpr += check_text ('#live_LeaAbfrage-'+`${uid}`+'-koop1ap_name',		'Kooperationspartner 1, Ansprechpartner Name');

			return text_ueberpr;
		}

		let text_ueberpr = "";

		text_ueberpr += check_text ('#live_LeaAbfrage-'+`${uid}`+'-firmen_name',		'Grunddaten, 1. Angaben zum Unternehmen: Firmenname');
		text_ueberpr += check_drpd ('#live_LeaAbfrage-'+`${uid}`+'-rechtsform',		'Grunddaten, 1. Angaben zum Unternehmen: Rechtsform');
		text_ueberpr += check_text ('#live_LeaAbfrage-'+`${uid}`+'-hauptsitz_ort',	'Grunddaten, 1. Angaben zum Unternehmen: Hauptsitz (Ort)');
		text_ueberpr += check_text ('#live_LeaAbfrage-'+`${uid}`+'-firma_strasse',	'Grunddaten, 1. Angaben zum Unternehmen: Strasse, Hausnr.');
		text_ueberpr += check_text ('#live_LeaAbfrage-'+`${uid}`+'-firma_p_l_z',		'Grunddaten, 1. Angaben zum Unternehmen: Postleitzahl');
		text_ueberpr += check_text ('#live_LeaAbfrage-'+`${uid}`+'-firma_ort',		'Grunddaten, 1. Angaben zum Unternehmen: Ort');

		text_ueberpr += check_firmengroesse();

		text_ueberpr += check_drpd ('#live_LeaAbfrage-'+`${uid}`+'-branche',			'Grunddaten, 1. Angaben zum Unternehmen: Branche');
		if( $('#live_LeaAbfrage-'+`${uid}`+'-branche').val() === '9' ) // branche
			text_ueberpr += check_text ('#live_LeaAbfrage-'+`${uid}`+'-branche_andere',	'Grunddaten, 1. Angaben zum Unternehmen: welche andere Branche?');
		text_ueberpr += check_text ('#live_LeaAbfrage-'+`${uid}`+'-ap_vorname',		'Grunddaten, 1. Angaben zum Unternehmen: Ansprechpartner Vorname');
		text_ueberpr += check_text ('#live_LeaAbfrage-'+`${uid}`+'-ap_name',			'Grunddaten, 1. Angaben zum Unternehmen: Ansprechpartner Name');
		text_ueberpr += check_text ('#live_LeaAbfrage-'+`${uid}`+'-ap_telefon',		'Grunddaten, 1. Angaben zum Unternehmen: Ansprechpartner Telefon');
		text_ueberpr += check_text ('#live_LeaAbfrage-'+`${uid}`+'-name_urkunde',		'Grunddaten, 1. Angaben zum Unternehmen: Unternehmensname auf der Urkunde');
		text_ueberpr += check_text ('#live_LeaAbfrage-'+`${uid}`+'-firmen_zweck',		'Grunddaten, 1. Angaben zum Unternehmen: Unternehmenszweck');
		text_ueberpr += check_drpd ('#live_LeaAbfrage-'+`${uid}`+'-mitbest_orga',		'Grunddaten, 1. Angaben zum Unternehmen: Form der betrieblichen Mitbestimmung im Unternehmen');
		if( $('#live_LeaAbfrage-'+`${uid}`+'-mitbest_orga').val() === '2' ) // mitbest_orga
			text_ueberpr += check_text ('#live_LeaAbfrage-'+`${uid}`+'-mitbest_orga_andere', 'Grunddaten, 1. Angaben zum Unternehmen: WELCHE Form der betrieblichen Mitbestimmung genau?');



		text_ueberpr += check_text ('#live_LeaAbfrage-'+`${uid}`+'-proj_titel',		'Projekt, 1. Titel/Überschrift');
		text_ueberpr += check_drpd ('#live_LeaAbfrage-'+`${uid}`+'-proj_eng_regelm',	'Projekt, 2. Regelmäßigkeit');

		text_ueberpr += check_zeitraum();

		text_ueberpr += check_text ('#live_LeaAbfrage-'+`${uid}`+'-proj_beschr',		'Projekt, 5. Projektbeschreibung');
		text_ueberpr += check_chkb (['#live_LeaAbfrage-'+`${uid}`+'-proj_kommu_intern', '#live_LeaAbfrage-'+`${uid}`+'-proj_kommu_extern', '#live_LeaAbfrage-'+`${uid}`+'-proj_kommu_keine'],
			'Projekt, 6. Wie kommunizieren Sie Ihr Kooperationsprojekt?');
		text_ueberpr += check_drpd ('#live_LeaAbfrage-'+`${uid}`+'-proj_kommu_rolle',	'Projekt, 7. Rolle Kooperationsprojekt in Unternehmenskommunikation');
		text_ueberpr += check_text ('#live_LeaAbfrage-'+`${uid}`+'-proj_was_bewirkt', 'Projekt, 8. Was hat Ihr Kooperationsprojekt bewirken können?');

		text_ueberpr += check_chkb (['#live_LeaAbfrage-'+`${uid}`+'-proj_mehrw_mitar_bind', '#live_LeaAbfrage-'+`${uid}`+'-proj_mehrw_reputation',
				'#live_LeaAbfrage-'+`${uid}`+'-proj_mehrw_verk_steig', '#live_LeaAbfrage-'+`${uid}`+'-proj_mehrw_untern_kultur',
				'#live_LeaAbfrage-'+`${uid}`+'-proj_mehrw_attr', '#live_LeaAbfrage-'+`${uid}`+'-proj_mehrw_weit',
				'#live_LeaAbfrage-'+`${uid}`+'-proj_mehrw_keinen'],
			'Projekt, 9. Welchen Mehrwert bietet Kooperationsprojekt für Unternehmen?');
		if( $('#live_LeaAbfrage-'+`${uid}`+'-proj_mehrw_weit').prop('checked') ) // proj_mehrw_weit
			text_ueberpr += check_text ('#live_LeaAbfrage-'+`${uid}`+'-proj_mehrw_weit_text', 'Projekt, 9. Text (Weiterer Mehrwert)');

		if( $('#lea-filelist-container #lea-filelist').length == 0 )
			text_ueberpr += 'Projekt, 10. Upload: Bitte nicht vergessen, Ihr Projekt wird für die Jury erst dann anschaulich, wenn Sie z.B. einige Bilder oder PDFs hochladen!' ;


		text_ueberpr += check_kooperationspartner();


		text_ueberpr += check_chkb (['#live_LeaAbfrage-'+`${uid}`+'-stat_wie_erf_newsl', '#live_LeaAbfrage-'+`${uid}`+'-stat_wie_erf_tel_lea',
				'#live_LeaAbfrage-'+`${uid}`+'-stat_wie_erf_magazin', '#live_LeaAbfrage-'+`${uid}`+'-stat_wie_erf_inet',
				'#live_LeaAbfrage-'+`${uid}`+'-stat_wie_erf_soc_media', '#live_LeaAbfrage-'+`${uid}`+'-stat_wie_erf_koop',
				'#live_LeaAbfrage-'+`${uid}`+'-stat_wie_erf_koll', '#live_LeaAbfrage-'+`${uid}`+'-stat_wie_erf_freunde',
				'#live_LeaAbfrage-'+`${uid}`+'-stat_wie_erf_wissensw', '#live_LeaAbfrage-'+`${uid}`+'-stat_wie_erf_eig_init',
				'#live_LeaAbfrage-'+`${uid}`+'-stat_wie_erf_lea_info', '#live_LeaAbfrage-'+`${uid}`+'-stat_wie_erf_sonst'],
			'Statistik, 1. Wie haben Sie von der Lea erfahren?');
		text_ueberpr += check_drpd ('#live_LeaAbfrage-'+`${uid}`+'-beworben_schonmal','Statistik, 2. In vergangenen Jahren bereits um Lea beworben?');


		if(text_ueberpr.length == 0){
			$('#zusammenfassung-container').html('<p><b>Super! Alles korrekt ausgefüllt!</b></p>');
			return true;
		}
		else {
			text_ueberpr = '<p><b>Folgende Punkte bitte überprüfen:</b></p>' + text_ueberpr;
			$('#zusammenfassung-container').html(text_ueberpr);
			return false;
		}
	}



	///////////////////////////////////////////////////////
	//////         Hinweis beim Einloggen        /////////
	/////////////////////////////////////////////////////
	$(
		'<div id="modalHinweis" class="modal fade" role="dialog">' +
		'  <div class="modal-dialog">' +
		'    <div class="modal-content">' +
		'      <div class="modal-header">' +
		'        <button type="button" class="close" data-bs-dismiss="modal">&times;</button>' +
		'        <h1 class="modal-title">Herzlich willkommen!</h1>' +
		'      </div>' +
		'      <div class="modal-body">' +
		'        <br><p>Bitte beachten Sie: Alle Ihre Angaben werden automatisch gespeichert.</p>' +
		'        <p>Auf den letzten beiden Seiten finden Sie den Button &bdquo;Bewerbung &uuml;berpr&uuml;fen&rdquo; und im weiteren Verlauf den Button &bdquo;Absenden&rdquo;, um Ihre endg&uuml;ltigen Angaben einzureichen.</p><br>' +
		//'<p>referrer=' + document.referrer + '</p><br>' +
		'      </div>' +
		'      <div class="modal-footer">' +
		'        <button id="modal-ok" type="button" class="btn" data-bs-dismiss="modal">Alles klar!</button>' +
		'      </div>' +
		'    </div>' +
		'  </div>' +
		'</div>'
	).appendTo('head');

	$('#modalHinweis').modal('show');


	///////////////////////////////////////////////////////
	//////         Hinweis beim Ausloggen        /////////
	/////////////////////////////////////////////////////

	$('a[href="?logintype=logout"]').click(function(event){

		event.preventDefault();

		$(
			'<div id="modalHinweisLogout" class="modal fade" role="dialog">' +
			'  <div class="modal-dialog">' +
			'    <div class="modal-content">' +
			'      <div class="modal-header">' +
			'        <button type="button" class="close" data-bs-dismiss="modal">&times;</button>' +
			'        <h1 class="modal-title">Auf Wiedersehen!</h1>' +
			'      </div>' +
			'      <div class="modal-body">' +
			'        <br><p>Danke, alle Felder Ihrer Bewerbung wurden automatisch gespeichert.</p>' +
			'        <p>Sie k&ouml;nnen bis zum 31. M&auml;rz jederzeit mit der Bearbeitung Ihrer Bewerbung fortfahren.</p><br>' +
			'      </div>' +
			'      <div class="modal-footer">' +
			'        <button id="modal-ok-logout" type="button" class="btn" data-bs-dismiss="modal">Alles klar!</button>' +
			'      </div>' +
			'    </div>' +
			'  </div>' +
			'</div>'
		).appendTo('body');

		$('#modalHinweisLogout').modal('show');

		$('#modal-ok-logout').click(function(){
			$('#modalHinweisLogout').modal('show');
			window.location.href = "?logintype=logout";
		});

	});



	//////////////////////////////////////
	//////   Postleitzahl-Felder   //////
	////////////////////////////////////

	// https://stackoverflow.com/questions/995183/how-to-allow-only-numeric-0-9-in-html-inputbox-using-jquery/995193#995193

	function PLZ(elemStr) {
		try{
			this.elem       = $(elemStr);
			if(this.elem.length != 1) return;
			this.infoboxStr = elemStr.substring(1) + '-plz';

			this.elem.parent().parent().append('<div id="' + this.infoboxStr +
				'" style="display:none; text-align:right; font-size:80%;  padding-left:5px; padding-right:5px;  position:relative; right:0px; ' +
				'box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.7); color: rgba(80, 140, 187, 1.0); background-color: rgba(51, 88, 105, 0.15)' +
				'"><span></span></div>');

			this.elem.keyup(this.keyup.bind(this));
			this.elem.focus(this.focus.bind(this));
			this.elem.focusout(this.focusout.bind(this));

			this.check();
		}catch(e){}
	}
	PLZ.prototype.check = function(e){
		var len = this.elem.val().length;
		if((len !== 5) && (len !== 0)){
			(this.elem).css('border-color', 'red');
			(this.elem).css('border-width', '2px');
			(this.elem).css('border-style', 'solid');
			$('#' + this.infoboxStr + ' span').text('Bitte genau fünf Ziffern!');
			$('#' + this.infoboxStr + ' span').css('color', 'red');

		}
		else if((len === 5) ){
			(this.elem).css('border-width', '2px');
			(this.elem).css('border-style', 'solid');
			(this.elem).css('border-color', 'green');
		}

		else{
			// (this.elem).css('border-style', 'none');
			$('#' + this.infoboxStr + ' span').text(' ');
			$('#' + this.infoboxStr + ' span').css('color', '');
		}
	};
	PLZ.prototype.focus = function(){
		$('#' + this.infoboxStr).show();
		this.check();
	};
	PLZ.prototype.focusout = function(){
		$('#' + this.infoboxStr).hide();
	};
	PLZ.prototype.keyup = function(e){
		this.elem.val( this.elem.val().replace(/[^0-9]/g, '') );
		this.check();
	};


	/////////////////////////////////////////////////////////////////
	////// anzeigen, ob Zahl "groesse_genau" im entspechenden
	/////  Bereich liegt, der im Dropdown "groesse" angegeben wurde
	////////////////////////////////////////////////////////////////

	// https://stackoverflow.com/questions/995183/how-to-allow-only-numeric-0-9-in-html-inputbox-using-jquery/995193#995193

	function Intervall(elemStr, dropdownStr) {
		try{
			this.elem      = $(elemStr);
			if(this.elem.length != 1) return;
			this.elemDropdown = $(dropdownStr);
			if(this.elemDropdown.length != 1) return;
			this.infoboxStr = elemStr.substring(1) + '-intervall';

			this.elem.parent().parent().append('<div id="' + this.infoboxStr +
				'" style="display:none; text-align:right; font-size:80%;  padding-left:5px; padding-right:5px;  position:absolute; right:15px; ' +
				'box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.7); color: rgba(80, 140, 187, 1.0); background-color: rgba(51, 88, 105, 0.15)' +
				'"><span></span></div>');

			this.elem.keyup(this.keyup.bind(this));
			this.elem.focus(this.focus.bind(this));
			this.elem.focusout(this.focusout.bind(this));

			this.elemDropdown.change(this.changeDropdown.bind(this))

			this.check();
		}catch(e){}
	}


	Intervall.prototype.check = function(e){
		var groesse = this.elem.val();
		var bereich = this.elemDropdown.val();
		if( (bereich == '1' && (groesse < 1 || groesse > 19))
			|| (bereich == '2' && (groesse < 20 || groesse > 149))
			|| (bereich == '3' && (groesse < 150 || groesse > 500)) ){
			(this.elem).css('border-color', 'red');
			(this.elem).css('border-width', '2px');
			(this.elem).css('border-style', 'solid');
			$('#' + this.infoboxStr + ' span').text('Die Anzahl muss im angegebenen Bereich liegen! Bitte anpassen!');
			$('#' + this.infoboxStr + ' span').css('color', 'red');
			//this.focus();
		}
		else if( (bereich == '1' && (groesse > 1 || groesse < 19))
			|| (bereich == '2' && (groesse > 20 || groesse < 149))
			|| (bereich == '3' && (groesse > 150 || groesse < 500)) ){
			(this.elem).css('border-color', 'green');
			(this.elem).css('border-width', '2px');
			(this.elem).css('border-style', 'solid');
			$('#' + this.infoboxStr + ' span').text('success');
			$('#' + this.infoboxStr + ' span').css('color', 'green');

			//this.focus();
		}

		else{
			(this.elem).css('border-color', 'grey');
			$('#' + this.infoboxStr + ' span').text(' ');
			$('#' + this.infoboxStr + ' span').css('color', '');
			(this.elem).css('border-width', '1px');
			//this.focus();
		}
	};
	Intervall.prototype.focus = function(){
		$('#' + this.infoboxStr).show();
		this.check();
	};
	Intervall.prototype.focusout = function(){
		$('#' + this.infoboxStr).hide();
	};
	Intervall.prototype.keyup = function(e){
		this.elem.val( this.elem.val().replace(/[^0-9]/g, '') );
		this.check();
	};
	Intervall.prototype.changeDropdown = function(e){
		this.check();
	};


	function DatefieldCeck(elemStr, pos, leer_erl) {
		try{
			this.elem         = $(elemStr);
			if(this.elem.length != 1) return;
			this.infoboxStr   = elemStr.substring(1) + '-dfl';
			this.leer_erlaubt = leer_erl;

			this.elem.parent().append('<div id="' + this.infoboxStr +
				'" style="display:none; text-align:right; font-size:80%;  padding-left:5px; padding-right:5px;  position:absolute; ' + pos + '; ' +   //right:15px; ' +
				'box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.7); color: rgba(80, 140, 187, 1.0); background-color: rgba(51, 88, 105, 0.15)' +
				'"><span></span></div>');

			this.elem.keyup(this.keyup.bind(this));
			this.elem.change(this.change.bind(this));  // focus
			this.elem.focus(this.focus.bind(this));
			this.elem.focusout(this.focusout.bind(this));

			//this.check();
		}catch(e){}
	}

	DatefieldCeck.prototype.check = function(e){ /*
		if(  (!this.leer_erlaubt) && (!Date.parse(this.elem.val()))  ){
			(this.elem).css({'border-color':'red', 'border-width':'2px', 'border-style':'solid'});
			$('#' + this.infoboxStr + ' span').text( 'Bitte ein gültiges Datum eingeben!' );
			$('#' + this.infoboxStr + ' span').css('color', 'red');
		}else{
			(this.elem).css('border-style', 'none');
			$('#' + this.infoboxStr + ' span').text(' ');
			$('#' + this.infoboxStr + ' span').css('color', '');
		} */
	};
	DatefieldCeck.prototype.change = function(){  // focus
		event.preventDefault();
		this.check();
		$('#' + this.infoboxStr).show();
	};
	DatefieldCeck.prototype.focusout = function(e){
		if( !Date.parse( this.elem.val() ) ) {
			this.elem.val(null);
			this.check();
		}
		else $('#' + this.infoboxStr).hide();
	};
	DatefieldCeck.prototype.focus = function(){
		$('#' + this.infoboxStr).show();
	};
	DatefieldCeck.prototype.keyup = function(e){
		//this.elem.val( this.elem.val().replace(/[^0-9]/g, '') );
		this.check();
		$('#' + this.infoboxStr).show();
	};



	///////////////////////////////////////////////
	//                  Autosave                //
	/////////////////////////////////////////////

	function registerAjaxLeaAutosave() {
		try{
			// Attach change event to all input fields
			$('body').on('change','.form__field, input', function (event) {
				// $('body').on('change', '.form-control, input[type="checkbox"]', function (event) {
				event.preventDefault();
				var $linkElement = $(this);
				var uri= $('.lea-savefield-blueprint').attr('href');

				var parameters = {};
				var fieldID = $linkElement.attr('id');
				var fieldValue = $linkElement.val().toString();

				// Check if the field is a checkbox and adjust the value accordingly
				if( $linkElement.attr('type')==='checkbox' ) {
					fieldValue = ($linkElement[0].checked) ? '1' : '0';
				}else{
					fieldValue = $linkElement.val().toString();
				}

				parameters['tx_leaform_leapisave[fieldID]']    = fieldID;
				parameters['tx_leaform_leapisave[fieldValue]'] = fieldValue;



				$.ajax(
					uri, { 'type':'post', 'data':parameters }
				).done(function (result) {
					var fst = $('.lea-savefield-blueprint .field-save-status');
					var elem = $('#' + fst.attr('name'));
					var target = elem.parent().parent();

					// Remove any previous status messages, if present
					target.children('.field-save-status').remove();

					// Append the new status message to the target
					fst.appendTo(target);

					if (elem.attr('type') === 'checkbox') {
						fst.css({ 'position': 'absolute', 'top': '0' });
						fst.css({ 'opacity': '0.0' });
					} else {
						fst.css({ 'opacity': '0.1' });
					}

					// // Fade in and fade out the status message
					fst.fadeIn(200).delay(600).fadeOut(200);
				});

				event.stopPropagation();

			});
		}catch(e){}
	}

	registerAjaxLeaAutosave();



	///////////////////////////////////////////////
	//    Dateiliste ins Formular kopieren      //
	/////////////////////////////////////////////


	var stu = $('.static-text-upload');
	if( stu ) {
		$('<div id="lea-upload" style="' +
			'font-size:90%; margin: 7px 0 7px 0;' +
			'background-color:rgb(100,100,100,0.1); padding:2px 0 1px 0; font-family: Helvetica Neue, Arial, sans-serif !important;' +
			'word-wrap:break-word; -moz-hyphens: auto; -o-hyphens: auto; -webkit-hyphens: auto; -ms-hyphens: auto; hyphens: auto; ' +
			'"></div>').appendTo(stu);

		filelistCheckBew();
	}


	function filelistCheckBew(){

		var uri			= $('.lea-savefield-blueprint').attr('href');
		var parameters	= {};
		/*
		var any_files_q = $('#lea-filelist-container #lea-filelist').length > 0;

		parameters['tx_leaform_leapisave[fieldID]']    = `${prefixId}bewertung_beant_media`;

		if(any_files_q) { parameters['tx_leaform_leapisave[fieldValue]'] = '1'; }
		else			{ parameters['tx_leaform_leapisave[fieldValue]'] = '0'; }
		*/
		$.ajax( uri, { 'type':'post', 'data': parameters } );

		// Liste ins Formular kopieren
		$('#lea-upload').empty();
		var c = $('#lea-filelist-container').clone();
		c.find('#lea-filelist-container').removeAttr('id');
		c.find('.pbfl-date').text('').width('20px');
		c.find('.pbfl-filesize').css('margin-left', '16px');
		c.find('.pbfl-filename').width('90%');

		c.appendTo('#lea-upload');
	}


	/////////////////////
	//     Upload     //
	///////////////////

	/// modal
	$(
		'<div id="modalUpload" class="modal fade" role="dialog">' +
		'  <div class="modal-dialog" style="width:100%; max-width:960px; margin:10px auto;">' +
		'    <div class="modal-content" style="background-color:rgba(240,240,240, 0.9);">' +
		'      <div class="modal-header" style="padding: 5px 15px; border-bottom:unset;">' +
		'        <button type="button" class="upload-dlg-close close" data-bs-dismiss="modal">&times;</button>' +
		'        <h1 class="modal-title">Upload-Dialog</h1>' +
		'      </div>' +
		'      <div id="lea-upload-modal-slot" class="modal-body" style="padding:0;">  </div>' +
		'      <div class="modal-footer">' +
		'        <button type="button" class="upload-dlg-close btn btn-default" data-bs-dismiss="modal">Schlie&szlig;en</button>' +
		'      </div>' +
		'    </div>' +
		'  </div>' +
		'</div>'
	).appendTo('body');

	$('#lea-filelist-box').appendTo('#lea-upload-modal-slot');// jQuery moves, if it's only one target..
	// $("<div id='#lea-filelist-box'></div>").appendTo('#lea-upload-modal-slot');// jQuery moves, if it's only one target..

	function registerAjaxLeaUpload(linkElementSelector, resultElementSelector, outputTime) {
		outputTime = outputTime || false;

		$('body').on('click', linkElementSelector, function (event) {
			event.preventDefault();
			var $linkElement = $(this);
			var uri = $linkElement.attr('href');
			var startTime = new Date().getTime();
			var duration = 0;
			$.ajax(
				uri, { 'type': 'post' }
			).done(function (result) {
				duration = new Date().getTime() - startTime;
				if (outputTime) {
					result = duration + 'ms <br>' + result;
				}

				var upl_slot = $('#uploader_LeaUpload1234').detach();
				var prnt = $(resultElementSelector).parent();
				$(resultElementSelector).remove();
				prnt.append(result);
				upl_slot.appendTo('#leapluploadfe-slot');
				$('#lea-filelist-box').css('display','block');

				//BE
				filelistCheckBew();
			});
		});

	}

	$('#uploader_LeaUpload1234').appendTo('#leapluploadfe-slot');// jQuery moves, if it's only one target..
	//$("<div id='#uploader_LeaUpload1234'></div>").appendTo('#leapluploadfe-slot');// jQuery moves, if it's only one target..
	//$('#uploader_LeaUpload1234').css('display','block'); //verschoben, soll nur im Formular sichtbar sein
	registerAjaxLeaUpload('.ajax-link', '#lea-filelist-box', false);

	$('.upload-dlg-close').click(
		function(){
			$('.plupload_button.plupload_start').click();
		}
	);


	//// BEGIN Upload-Button
	$('<div><a id="button-lea-upload" class="btn btn-primary" ' +
		'style="padding:0 6px; font-size: 16.2px !important; line-height: 23.15px !important;"> ' +
		'Datei-Upload</a> </div>'
	).appendTo('.static-text-upload');// .tx-pluploadfe-pi1   TMP-TST

	$('#button-lea-upload').click(function(event){
		$('#modalUpload').modal('show');
		$('#intro-button-up').css('visibility','hidden');

	});
	//// END Upload-Button



	////////////////////////////////////////////////
	//  Enter-Key in Input-Feldern unterdrücken  //
	//////////////////////////////////////////////
	// https://stackoverflow.com/questions/585396/how-to-prevent-enter-keypress-to-submit-a-web-form

	const keyStop = {
		//8: ":not(input:text, textarea, input:file, input:password)", // stop backspace = back
		13: 'input:text, input:password', // stop enter = submit
		end: null
	};
	$('.frame-type-form_formframework').bind('keydown', function(event){
		const selector = keyStop[event.which];

		if(selector !== undefined && $(event.target).is(selector)) {
			event.preventDefault(); //stop event
		}
		return true;
	});



	///////////////////////////////////////////////////////////////
	////// verfügbaren Anzahl Zeichen in Editboxen anzeigen //////
	/////////////////////////////////////////////////////////////

	function Countdown(elemStr, max) {
		try{
			this.elem      = $(elemStr);
			if(this.elem.length != 1) return;
			this.maxLength = max;
			this.infoboxStr= elemStr.substring(1) + '-countdown';

			if(this.maxLength === undefined){
				var propmaxlen = this.elem.prop('maxlength');
				if((propmaxlen === undefined) || (propmaxlen < 0))
					this.maxLength = 3000;
				else
					this.maxLength = parseInt(propmaxlen);
			}
			this.elem.parent().parent().append('<div id="' + this.infoboxStr +
				'" style="display:none; text-align:right; font-size:80%;  padding-left:5px; padding-right:5px;  position:absolute; right:15px; ' +
				'box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.7); color: rgba(80, 140, 187, 1.0); background-color: rgba(51, 88, 105, 0.15)' +
				'"><span></span></div>');

			this.elem.keyup(this.keyup.bind(this));
			this.elem.focus(this.focus.bind(this));
			this.elem.focusout(this.focusout.bind(this));

			this.check();

		}catch(e){}
	}
	Countdown.prototype.check = function(e){
		const tmp = this.elem.val().replace(/(\r\n|\n|\r)/g,"  "); // count 2 characters for /n or /r
		const len = (this.maxLength) - (tmp.length);

		if(len<0){
			(this.elem).css('border-color', 'red');
			(this.elem).css('border-width', '2px');
			(this.elem).css('border-style', 'solid');
			$('#' + this.infoboxStr + ' span').text('Ihr Text ist um ' + (-len) + ' Zeichen zu lang! Bitte anpassen!');
			$('#' + this.infoboxStr + ' span').css('color', 'red');
			this.focus();
		}else{
			// (this.elem).css('border-style', 'none');
			$('#' + this.infoboxStr + ' span').text('Noch max. ' + len + ' Zeichen...');
			$('#' + this.infoboxStr + ' span').css('color', '');
		}
	};
	Countdown.prototype.focus = function(){
		const len = (this.maxLength) - (this.elem.val().length);
		if(len < (this.maxLength * 1.0)) // *0.9
			$('#' + this.infoboxStr).show();
		else
			$('#' + this.infoboxStr).hide();
	};
	Countdown.prototype.focusout = function(){
		$('#' + this.infoboxStr).hide();
	};
	Countdown.prototype.keyup = function(e){
		this.check();
		this.focus();
	};


	////////////////////////////////////////////////////
	////// Aktiv-/Inaktivschalten von Textfeldern //////
	////////////////////////////////////////////////////

	//  https://stackoverflow.com/questions/10547622/trigger-change-event-select-using-jquery
	//  https://stackoverflow.com/questions/2330209/jquery-checkbox-enable-disable
	function CheckSelect(select_elem_str, select_val, input_elem_str) {
		try{
			this.selectElem = $(select_elem_str);
			if(this.selectElem.length != 1) return;
			this.selectVal  = select_val;
			this.inputElem  = input_elem_str;

			this.selectElem.change(this.check.bind(this));
			this.check();
		}catch(e){}
	}
	CheckSelect.prototype.check = function(){
		let condition = false;

		if(this.selectElem.prop('type') == 'checkbox')
			condition = (this.selectElem.prop('checked') == this.selectVal);
		else
			condition = (this.selectElem.val() == this.selectVal);

		const ilen = this.inputElem.length;
		for(let i=0; i<ilen; i++){
			if(condition){
				$(this.inputElem[i]).prop('readonly', false).removeAttr('disabled').parent().css('opacity', '1.0');
				$(this.inputElem[i] + ' + label.checkmark').parent().css('opacity', '1.0');
				if( $(this.inputElem[i]).prop('type') == 'checkbox' ) {
					try{  $(this.inputElem[i]).trigger('change');
					}catch(e){}
				}
			}else{
				$(this.inputElem[i]).prop('readonly', true).attr('disabled', true).parent().css('opacity', '0.5');
				$(this.inputElem[i] + ' + label.checkmark').parent().css('opacity', '0.5');
				if(this.selectElem.prop('type') == 'checkbox')
					$(this.inputElem[i]).prop('checked', false);
			}
		}
	};



	//////// "intern, extern, weder noch" ///////////////////
	function set_chckb(field, val) // Feldname ohne '#'
	{
		$(field).prop('checked', (val?true:false) );

		$.ajax( $('.lea-savefield-blueprint').attr('href'),
			{ 'type':'post', 'data':{ 'tx_leaform_leapisave[fieldID]' : field, 'tx_leaform_leapisave[fieldValue]' : (val?'1':'0')  } }
		).done(function (result) { } );
	}


	//////////////////////////////////
	//////    FORM-Infoboxen    //////
	//////////////////////////////////

	function set_infobox(elem_str, info_text, marginTop = '20px')
	{
		const info_button_html = '<span class="lea-info-button form-control">?</span>';
		const element_css      = {'margin-right':'5px', 'width':'100%'};
		const div_input_css    = {'position':'relative',
			'display':'-webkit-flex',				'display':'flex',
		};
		const div_info_pre     = '<div class="lea-info" style="' +
			'display:none; position:absolute; width:80%; top:28px; right:0; z-index:50;   ' +
			'background-color:#fff; font-size:80% !important; line-height:108% !important; box-shadow: 0 0 10px rgba(100, 100, 100, 0.8);' +
			'padding:7px; max-height:14em; overflow-y:auto; ' +
			'"> <span style="">';
		const div_info_post  = '</span></div>';

		try{
			// Texte und Hover setzen
			var elem = $(elem_str);
			if(elem.length != 1) return;
			elem.css(element_css).parent('div').css(div_input_css).append(info_button_html).append($( div_info_pre + info_text + div_info_post ));

			if(elem.hasClass('static-text-extended')){
				elem.next()       .css({'margin-top':marginTop});
				elem.next().next().css({'margin-top':marginTop});
			}
		}catch(e){}
	}

	const rechtsformFaq = $('.static-text-rechtsform-faq');
	rechtsformFaq.html('Sollten Sie Ihre Rechtsform hier nicht finden, kontaktieren Sie uns bitte unter 0711 263 311 47 oder schauen Sie in unseren <a href="/lea-bw/faq">FAQs</a> nach.');

	const idMax = {
		'firmen_name': 250,
		'hauptsitz_ort': 250,
		'firma_strasse': 250,
		'firma_ort': 250,
		'branche_andere': 250,
		'ap_titel': 250,
		'ap_vorname': 250,
		'ap_name': 250,
		'ap_funktion': 250,
		'ap_telefon': 250,
		'name_urkunde': 120,
		'firmen_zweck': 600,
		'mitbest_orga_andere': 600,
		'proj_titel': 120,
		'proj_beschr': 2000,
		'proj_was_bewirkt': 1500,
		'proj_mehrw_weit_text': 250,
		'proj_links': 1500,
		'koop1_name': 250,
		'koop1_strasse': 250,
		'koop1_ort': 250,
		'koop1ap_vorname': 250,
		'koop1ap_name': 250,
		'koop1ap_telefon': 250,
		'koop1ap_email': 250,
		'koop2_name': 250,
		'koop2_strasse': 250,
		'koop2_ort': 250,
		'koop2ap_vorname': 250,
		'koop2ap_name': 250,
		'koop2ap_telefon': 250,
		'koop2ap_email': 250,
		'koop3_name': 250,
		'koop3_strasse': 250,
		'koop3_ort': 250,
		'koop3ap_vorname': 250,
		'koop3ap_name': 250,
		'koop3ap_telefon': 250,
		'koop3ap_email': 250,
		'koop_weitere': 2000,
		'beworben_jahre': 250
	}

	const infoTexts = {
		'hauptsitz_ort': 'Es k&ouml;nnen sich ausschlie&szlig;lich Unternehmen mit <strong>Hauptsitz</strong> in Baden-W&uuml;rttemberg bewerben.',
		'groesse': 'Ma&szlig;geblich ist die <strong>Summe</strong> der Teil-/ Vollzeitbesch&auml;ftigten. Beispiel: Zehn Besch&auml;ftigte mit einem Stellenumfang von je 50 Prozent werden hier als f&uuml;nf Arbeitnehmer gez&auml;hlt.',
		'name_urkunde': 'Der Name Ihres Unternehmens erscheint auf Ihrer Urkunde so, wie Sie ihn hier angeben. Bitte beachten Sie, dass nach Einreichung Ihrer Bewerbung keine &Auml;nderungen mehr m&ouml;glich sind.',
		'mitbest_orga': 'Betriebliche Mitbestimmung umfasst hier sowohl institutionalisierte Formen (bspw. Betriebsrat) als auch informellere Formate der Mitbestimmung und Partizipation von Mitarbeitern.'
	}


	const firstElement = document.querySelector('[id^="live_LeaAbfrage"]')

	const id = firstElement.id
	let element
	const clearedId = id.replace(clearPrefix, '')

	if(uid === 0) {
		uid = id.replace('live_LeaAbfrage-', '').replace(`-${clearedId}`, '')
		prefixId = `#live_LeaAbfrage-${uid}-`
		submitButton = document.querySelector(`[name="tx_form_formframework[live_LeaAbfrage-${uid}][__currentPage]"]`)
		alreadySend = $(`${prefixId}versendet`).prop('checked')
	}

	new CheckSelect(`${prefixId}branche`, '9', [
		`${prefixId}branche_andere`]);

	new CheckSelect(`${prefixId}mitbest_orga`, '2', [
		`${prefixId}mitbest_orga_andere`
	]);

	new CheckSelect(`${prefixId}beworben_schonmal`, '1', [
		`${prefixId}beworben_jahre`
	]);

	new CheckSelect(`${prefixId}proj_mehrw_weit`, true, [
		`${prefixId}proj_mehrw_weit_text`
	]);

	new CheckSelect(`${prefixId}proj_mehrw_keinen`, false, [
		`${prefixId}proj_mehrw_mitar_bind`,
		`${prefixId}proj_mehrw_reputation`,
		`${prefixId}proj_mehrw_verk_steig`,
		`${prefixId}proj_mehrw_untern_kultur`,
		`${prefixId}proj_mehrw_attr`,
		`${prefixId}proj_mehrw_weit`,
		`${prefixId}proj_mehrw_weit_text`,
	]);

	new CheckSelect(`${prefixId}stat_wie_erf_sonst`, true, [
		`${prefixId}stat_wie_erf_sonst_text`
	]);

	new CheckSelect(`${prefixId}proj_kommu_keine`, false, [
		`${prefixId}proj_kommu_intern`,
		`${prefixId}proj_kommu_extern`,
	]);
	element = document.querySelector(`${prefixId}proj_kommu_keine`)
	element.addEventListener('click', (event) => {
		if(!element.checked) return
		set_chckb(`${prefixId}proj_kommu_intern`, false);
		set_chckb(`${prefixId}proj_kommu_extern`, false);
	})

	document.querySelectorAll('[id^="live_LeaAbfrage"]').forEach((element) => {
		const id = element.id
		const elementId = `#${id}`
		const clearedId = id.replace(clearPrefix, '')

		const maxCountdown = idMax[clearedId]

		if(maxCountdown) {
			new Countdown(elementId, maxCountdown)
			return
		}

		const infoText = infoTexts[clearedId]

		if(infoText) {
			set_infobox(elementId, infoText)
			return;
		}

		switch (clearedId) {
			case 'ap_email':
				element.readonly = true
				break
			case 'proj_bis':
				let tempEnd = $( elementId )
					.removeAttr('type')
					.attr('type', 'date')
					.attr('placeholder', 'TT.MM.JJJJ')
					.css({'max-width':'150px'})

				tempEnd = tempEnd.parent().parent()
				tempEnd
					.css({ 'width':'46%','float':'right' })
					.insertAfter('.zeitraum-container')

				new DatefieldCeck(elementId, 'right:15px', false);
				break
			case 'proj_von':
				let tempStart = $( elementId )
					.removeAttr('type')
					.attr('type', 'date')
					.attr('placeholder', 'TT.MM.JJJJ')
					.css({'max-width':'150px'})

				tempStart = tempStart.parent().parent()
				tempStart
					.css({ 'width':'46%', 'margin-right':'15px', 'float':'left' })
					.insertAfter('.zeitraum-container')

				new DatefieldCeck(elementId, 'left:15px', false);
				break
			case 'firma_p_l_z':
			case 'koop1_p_l_z':
			case 'koop2_p_l_z':
			case 'koop3_p_l_z':
				new PLZ(elementId)
				break
			case 'groesse_genau':
				new Intervall(elementId, `${prefixId}groesse`);
				break
		}
	})

	// function set_versendet(vers)
	// {
	// 	if( ! bereits_versendet ){
	// 		$('#live_LeaAbfrage-101-versendet').prop('checked', (vers?true:false) ); // window.location.href = "?logintype=logout";
	// 		$.ajax( $('.lea-savefield-blueprint').attr('href'),
	// 			{ 'type':'post', 'data':{ 'tx_leaform_leapisave[fieldID]':'live_LeaAbfrage-101-versendet', 'tx_leaform_leapisave[fieldValue]': (vers?'1':'0')  } }
	// 		).done(function (result) { /*alert('versenden per AJAX: ' + result);*/ } );
	// 		bereits_versendet = true;
	// 	}
	// }


	set_infobox('.static-text-upload',
		'Ihr Projekt wird für die Jury anschaulich, wenn Sie uns Bilder, Zeitungsberichte oder Social Media Posts zur Verf&uuml;gung stellen.<br>' +
		'Bitte laden Sie hier Bilder oder Anschauungsmaterial Ihres Projekts hoch, mit dem Sie sich in diesem Jahr bei der Lea bewerben.<br><br>' +
		'<span style="color:red">Umfangreiche Nachhaltigkeitsberichte oder Informationsmaterial k&ouml;nnen wir bei der Bewertung inhaltlich nicht ber&uuml;cksichtigen.<br>' +
		'Bitte laden Sie ausschlie&szlig;lich Dateien im PDF-, JPG-, PNG- oder geschlossenen PowerPoint-Format mit h&ouml;chstens 15MB hoch und achten Sie darauf, im Dateinamen keine Sonderzeichen zu verwenden. Maximal 8 Dateien!<span>',
		'5px'
	);


	set_infobox('.static-text-kooppartner',
		'Ein wesentliches Teilnahmekriterium bei der Lea ist, dass Sie Ihr Projekt mit einem gemeinn&uuml;tzigen Kooperationspartner aus dem Gemeinwesen umsetzen.<br>' +
		'Ihr Kooperationspartner kann bspw. eine gemeinn&uuml;tzige GmbH, ein gemeinn&uuml;tziger Verein oder eine gemeinn&uuml;tzige Stiftung sein.');


	$('.lea-info-button').click( function() {
		var box  = $(this).next();
		var butt = $(this);
		if(box.css('display') === 'block'){
			$('.lea-info').hide().prev().text('?').css({'cursor':'help'});
			box.hide();
			butt.text('?').css({'cursor':'help'});
		}else{
			$('.lea-info').hide().prev().text('?').css({'cursor':'help'});
			box.show();
			butt.text('x').css({'cursor':'pointer'});
		}
	});
	$('.lea-info').click( function() {
		var box  = $(this);
		var butt = $(this).prev();
		if(box.css('display') === 'block'){
			$('.lea-info').hide().prev().text('?').css({'cursor':'help'});
			box.hide();
			butt.text('?').css({'cursor':'help'});
		}else{
			$('.lea-info').hide().prev().text('?').css({'cursor':'help'});
			box.show();
			butt.text('x').css({'cursor':'pointer'});
		}
	});

	submitButton.addEventListener('click', event => {
		event.preventDefault();

		const bewerbung_korrekt = check_bewerbung();
		let modal_titel, modal_text, modal_buttons;

		if(bewerbung_korrekt){
			set_versendet(true);

			modal_titel = '<b>Vielen Dank!</b>';
			modal_text =
				'<br><b><p style="font-size:120%">Schön, dass Sie Ihren Hut in den Ring werfen. Ihre Bewerbung ist vollst&auml;ndig ausgef&uuml;llt und damit bereit zum Absenden!</p></b><br>' +

				'<p>Alternativ k&ouml;nnen Sie aber auch bis spätestens zum 31. M&auml;rz noch &Auml;nderungen an Ihrer Bewerbung vornehmen und erst dann absenden.</p><br>' +
				'<p>Bei Fragen zum Bewerbungsbogen erreichen Sie uns unter 0711 2633 11 47. Das PDF zu Ihrer Bewerbung können Sie sich bis zum 31. M&auml;rz des aktuellen Jahres herunterladen.</p><br>' +

				'<b><p style="font-size:120%">Viel Gl&uuml;ck und ein herzliches &bdquo;Roaaar&rdquo; von Ihrer Lea!</p></b><br>' ;
			modal_buttons =
				'<button id="modal-ok-absenden" class="btn btn-primary" type="submit" name="tx_form_formframework[live_LeaAbfrage-'+`${uid}`+'][__currentPage]" value="1" style="color:#000; background-color:#ffc720; border-color:#7f6b10;"> <b>Absenden</b> </button>' +
				'<button id="modal-cancel"   class="btn" type="button" data-bs-dismiss="modal">Erst sp&auml;ter</button>' ;
		}else{
			modal_titel = 'Bewerbung &uuml;berpr&uuml;fen';
			modal_text =
				'<br><p>Ihre Bewerbung wurde kurz auf Vollst&auml;ndigkeit &uuml;berpr&uuml;ft und es gibt noch ein paar Beanstandungen.</p>' +
				'<p>Im Reiter &bdquo;Zusammenfassung&rdquo; sind alle zu korrigierenden Punkte aufgelistet.</p>' ;
			modal_buttons =
				'<button id="modal-cancel"     type="button" class="btn" data-bs-dismiss="modal">Alles klar!</button>' ;
		}

		$('#modalHinweisAbsenden').remove();
		$(	'<div id="modalHinweisAbsenden" class="modal fade" role="dialog">' +
			'  <div class="modal-dialog">' +
			'    <div class="modal-content">' +
			'      <div class="modal-header">' +
			'        <button type="button" class="close" data-bs-dismiss="modal">&times;</button>' +
			'        <h1 class="modal-title">' + modal_titel + '</h1>' +
			'      </div>' +
			'      <div class="modal-body">' + modal_text +
			'      </div>' +
			'      <div class="modal-footer">' + modal_buttons +
			'      </div>' +
			'    </div>' +
			'  </div>' +
			'</div>'
		).appendTo('#tab_cc');

		$('#modalHinweisAbsenden').modal('show');

		//$('#modal-ok-absenden').click(function(event){ });
		$('#modal-cancel').click(function(){ alreadySend = false });
		$('#modalHinweisAbsenden').click(function(){ alreadySend = false });

	})

	////////////////////////
	// Absenden-Button //
	//////////////////////

	$('.btn-toolbar').insertAfter('#tab_container_4');

	$(submitButton).removeClass('btn').css({
		'font-size':		'130%',
		'background-color':	'#ffc720',	//'rgb(255, 215, 32)',
		'color':			'#000',
		'border':			'1px solid #7f6b10',	//'rgb(127, 107, 16)',
		'margin-bottom': 	'0',
		'margin-left': 		'15px',
		'font-weight':		'normal',
		'text-align':		'center',
		'touch-action':		'manipulation',
		'cursor':			'pointer',
		'white-space':		'nowrap',
		'line-height':		'1.42857',
		'user-select':		'none',
		'display':			'none'
	});

	$(submitButton).text("Bewerbung überprüfen");


	$(`#live_LeaAbfrage-${uid} h2:first`).replaceWith(reiter + content + reiter);


	///////////////////////////////////////////////////////
	////   Überprüfung der Felder vor dem Absenden    ////
	/////////////////////////////////////////////////////

	function set_versendet(vers)
	{
	 	if( ! alreadySend ){
	 		$(`${prefixId}versendet`).prop('checked', (vers?true:false) ); // window.location.href = "?logintype=logout";
	 		$.ajax( $('.lea-savefield-blueprint').attr('href'),
	 			{ 'type':'post', 'data':{ 'tx_leaform_leapisave[fieldID]':'live_LeaAbfrage-'+`${uid}`+'-versendet', 'tx_leaform_leapisave[fieldValue]': (vers?'1':'0')  } }
	 		).done(function (result) { /*alert('versenden per AJAX: ' + result);*/ } );
	 		alreadySend = true;
	 	}
	}


	create_tab( 0, 'block' ); create_tab( 1, 'none' ); create_tab( 2, 'none' ); create_tab( 3, 'none' );

	/////////////////////////
	// Vor/Zurück-Buttons //
	///////////////////////

	const vzButtons =
		'<div id="vz_buttons" style="position:relative; display:-webkit-flex; display:flex; margin:35px 15px; cursor:pointer;" >' +
		'<div id="button_zurueck" class="leabtn" ' +
		'style="display:none; width: 97px; height: 26px; text-align:center; padding 15px; color: white; ' +
		'vertical-align:middle; font-size: 100%; margin-right:5px; background-color:#E3E3E3;' +
		'">Zur&uuml;ck</div>' +
		'<div id="button_vor" class="leabtn" ' +
		'style="display:inline; width: 97px; height: 26px; text-align:center; padding 15px; color: white; ' +
		'vertical-align:middle; font-size: 100%; margin-right:5px; background-color:#E3E3E3;' +
		'">Vor</div>' +
		'</div>';

	$(vzButtons).insertAfter(submitButton);

	$('body').on('click', '#button_zurueck', function (event) {
		if(global_tab_number > 0){
			var old_tab = global_tab_number;
			global_tab_number -= 1 ;
			goto_tab( old_tab, global_tab_number );
		}
	});
	$('body').on('click', '#button_vor', function (event) {
		if(global_tab_number < 4){
			var old_tab = global_tab_number;
			global_tab_number += 1 ;
			goto_tab( old_tab, global_tab_number );
		}
	});

	$('.tab_'+global_tab_number).css({'background-color':'#ffd720', 'color':'#444'});
}