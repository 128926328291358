import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '../Scss/includes.scss';

window.addEventListener('load', async () => {
    const modules = import.meta.glob([
        './Content/*.js',
        './Leaform/**/*.js',
    ]);

    const loadPromises = Object.keys(modules).map(async (path) => {
        try {
            const module = await modules[path]();

            if(location.hostname.endsWith('.ddev.site')) {
                console.log(`Loaded module: ${path}`, module);
            }
        } catch (error) {
            console.error(`Error loading module ${path}`, error);
        }
    });

    await Promise.all(loadPromises); // Wait for all modules to load
});

